<template>
  <ul class="realtime-statistics-recommendation-list">
    <li v-for="(item, index) in recommendations" :key="index" class="realtime-statistics-recommendation-list__item">
      <span class="fa fa-bell realtime-statistics-recommendation-list__item__icon" :class="[severityClass(item.severity)]"></span>
      {{ message(item.messageData) }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'RealtimeStatisticsRecommendationList',
  props: {
    recommendations: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  methods: {
    severityClass (severity) {
      return {
        'realtime-statistics-recommendation-list__item__icon--warning': severity === 2,
        'realtime-statistics-recommendation-list__item__icon--danger': severity === 1
      }
    },
    message (message) {
      if (!message) {
        return this.$t('realtimeStatistics.messages.error')
      }
      return this.$t(`realtimeStatistics.messages.${message.id}`, { optimalValue: message.optimalValue || 0, realValue: message.realValue || 0 })
    }
  }
}
</script>
<style lang="scss" scoped>
.realtime-statistics-recommendation-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    align-items: center;
    gap: rem(16px);
    margin-bottom: rem(4px);

    &__icon {
      &--warning {
        color: var(--warning);
      }
      &--danger {
        color: var(--danger);
      }
    }
  }
}
</style>
