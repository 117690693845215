<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="topArticles"
    :show-pagination="false"
    actionsStyle="margin-left: auto;"
    :current-page="1"
    :on-page-change="() => {}"
    table-css-class="realtime-statistics-table"
    columns-gap="10px"
  >
    <template #tableBody="{ item, index }">
      <div
        class="row-background"
        :class="getRowCssClass(item)"
        @click="toggleExpand(item, index)"
      />
      <AbstractColumn header="">
        <ArrowDownIcon
          :class="`arrow-icon ${isExpanded(item) ? 'arrow-icon--expanded' : ''}`"
          :key="isExpanded(item)"
        />
      </AbstractColumn>
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.users"
        subtitle
      />
      <AbstractColumn :header="tableHeaders[2].name">
        <div v-if="filteredRecommendations(item.recommendations).length" :class="['fa fa-lg fa-bell bell-icon', iconBellClass(item.recommendations)]">
          <span class="bell-icon__text">{{ filteredRecommendations(item.recommendations).length }}</span>
        </div>
      </AbstractColumn>
      <AbstractColumn :header="tableHeaders[3].name">
        <em
          :title="$t('dashboard.tooltip.order_change_up')"
          class="fa fa-arrow-up"
          aria-hidden="true"
          v-if="item.hasOwnProperty('orderChangeUp')"
          style="color: #41b883;"
        >
          &nbsp;
        </em>
        <em
          :title="$t('dashboard.tooltip.order_change_down')"
          class="fa fa-arrow-down"
          aria-hidden="true"
          v-if="item.hasOwnProperty('orderChangeDown')"
          style="color: #fc4b6c;"
        >
          &nbsp;
        </em>
        <em
          :title="$t('dashboard.tooltip.order_change_none')"
          class="fa fa-dot-circle"
          aria-hidden="true"
          v-if="item.hasOwnProperty('orderChangeNone')"
          style="color: #1693c1;"
        >
          &nbsp;
        </em>
      </AbstractColumn>
      <AbstractColumn
        :header="tableHeaders[4].name"
      >
        <div class="abstract-data-table__td-title">
          {{ item.title }}
        </div>
        <RealtimeStatisticsArticleInfoColumn :article="item" />
      </AbstractColumn>
      <TitleColumn
        :title-header="tableHeaders[5].name"
        :title-value="item.pageViews | formatNumber"
        :column-style="alignRight"
      />
      <TitleColumn
        :title-header="tableHeaders[6].name"
        :title-value="item.videoViews | formatNumber"
        :column-style="alignRight"
      />
      <TitleColumn
        :title-header="tableHeaders[7].name"
        :title-value="getRecirculation(item) | formatPercentage"
        :column-style="alignRight"
      />
      <TitleColumn
        :title-header="tableHeaders[8].name"
        :title-value="item.readingTime | formatSeconds('m:ss')"
        :column-style="alignRight"
      />
    </template>
    <template #actions="{ item }">
      <ButtonTableAction
        class="info-button"
        icon="info"
        :title="$t('route.article_detail')"
        @click.stop="openArticleDetail(item.documentId)"
      />
    </template>
    <template #afterActions="{ item, index }">
      <AbstractColumn
        class="expand-button-mobile-view"
        header=""
      >
        <ModuleFormButton @click="toggleExpand(item, index)">
          <ArrowDownIcon
            :class="`arrow-icon arrow-icon--in-button ${isExpanded(item) ? 'arrow-icon--expanded' : ''}`"
            :key="isExpanded(item)"
          />
          {{ $t('dashboard.show_chart') }}
        </ModuleFormButton>
      </AbstractColumn>
      <AbstractColumn
        v-show="isExpanded(item)"
        :key="`${item.documentId}-${isExpanded(item)}`"
        class="article-chart"
        header=""
      >
        <RealtimeStatisticsRecommendationList :recommendations="filteredRecommendations(item.recommendations)" />
        <RealtimeStatisticsChart
          :ref="`statisticsChart-${index}`"
          :document-id="item.documentId"
          is-article-chart
        />
      </AbstractColumn>
    </template>
  </AbstractDataTable>
</template>

<script>
import { mapGetters } from 'vuex'
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import AbstractColumn from '@/components/table/columns/AbstractColumn'
import ArrowDownIcon from '@/assets/img/svg/arrow-down.svg?inline'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'
import RealtimeStatisticsChart from '@/components/statistics/RealtimeStatisticsChart'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import RealtimeStatisticsArticleInfoColumn from '@/components/statistics/RealtimeStatisticsArticleInfoColumn'
import RealtimeStatisticsRecommendationList from '@/components/statistics/RealtimeStatisticsRecommendationList.vue'

export default {
  name: 'RealtimeStatisticsTable',
  props: {
    topArticles: {
      type: Array,
      required: true
    },
    selectedSite: {
      type: Object,
      default: () => {
        return { id: null }
      }
    }
  },
  components: {
    RealtimeStatisticsRecommendationList,
    RealtimeStatisticsArticleInfoColumn,
    ModuleFormButton,
    RealtimeStatisticsChart,
    ButtonTableAction,
    ArrowDownIcon,
    AbstractColumn,
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      expanded: {},
      alignRight: 'text-align: right;'
    }
  },
  computed: {
    ...mapGetters({
      getSiteTitle: 'site/getSiteTitle'
    }),
    someExpanded () {
      return Object.keys(this.expanded).some(key => this.expanded[key])
    },
    tableHeaders () {
      return [
        { name: '' }, // expanded column
        {
          name: this.$t('dashboard.people'),
          tooltip: this.$t('dashboard.tooltip.people')
        },
        {
          name: this.$t('dashboard.recommendation')
        },
        {
          name: this.$t('dashboard.status')
        },
        {
          name: this.$t('dashboard.article_title'),
          tooltip: this.$t('dashboard.tooltip.article_title')
        },
        {
          name: this.$t('dashboard.page_views'),
          tooltip: this.$t('dashboard.tooltip.page_views'),
          style: this.alignRight
        },
        {
          name: this.$t('dashboard.video_views'),
          tooltip: this.$t('dashboard.tooltip.video_views'),
          style: this.alignRight
        },
        {
          name: this.$t('dashboard.recirculation'),
          tooltip: this.$t('dashboard.tooltip.recirculation'),
          style: this.alignRight
        },
        {
          name: this.$t('dashboard.readingTime'),
          tooltip: this.$t('dashboard.readingTime'),
          style: this.alignRight
        },
        {
          name: this.$t('actions'),
          style: this.alignRight
        }
      ]
    }
  },
  methods: {
    isExpanded (item) {
      return Boolean(this.expanded?.[item.documentId])
    },
    getRecirculation (item) {
      return 1 - (item.bounceRate ?? 0)
    },
    getRowCssClass (item) {
      const list = []
      this.isExpanded(item) && list.push('row-expanded')
      if (item.orderChangeDown) {
        list.push('row-down')
      } else if (item.orderChangeUp) {
        list.push('row-up')
      }
      return list
    },
    clearChart (item) {
      this.$store.commit('realtimeStatistics/removeArticleChartApiData', item.documentId)
    },
    async toggleExpand (item, index) {
      this.clearChart(item)
      this.expanded[item.documentId] = !this.expanded[item.documentId]
      if (this.expanded[item.documentId]) {
        this.$emit('some-expanded', true)
        this.$nextTick(async () => {
          await this.$refs[`statisticsChart-${index}`].reload()
        })
      } else {
        this.$emit('some-expanded', this.someExpanded)
        this.clearChart(item)
      }
    },
    openArticleDetail (documentId) {
      window.open(`/articleByDocumentId/${documentId}`, '_blank')
    },
    iconBellClass (recommendations) {
      const filteredRecommendations = this.filteredRecommendations(recommendations)
      const severities = filteredRecommendations.map(rec => rec.severity)
      const lowestSeverity = Math.min(...severities)
      if (lowestSeverity === 1) {
        return 'bell-icon--danger'
      } else if (lowestSeverity === 2) {
        return 'bell-icon--warning'
      }
      return ''
    },
    filteredRecommendations (recommendations) {
      if (!recommendations) {
        return []
      }
      return recommendations
        .filter((item) => item.messageData.id <= 4)
        .filter(item => item.messageData.id !== 0 || this.selectedSite.id === 'plus7')
    }
  },
  watch: {
    topArticles (newValue) {
      this.expanded = newValue.reduce((acc, item) => {
        acc[item.documentId] = this.expanded?.[item.documentId] ?? false
        return acc
      }, {})
      this.$emit('some-expanded', this.someExpanded)
    }
  }
}
</script>
<style lang="scss">
.realtime-statistics-table {
  &__thead,
  &__tr {
    @include bp(0 16) {
      grid-template-columns: rem(20px) rem(60px) rem(60px) rem(60px) auto rem(81px) rem(81px) rem(81px) rem(61px) rem(51px) max-content;
    }
    @include bp(16) {
      grid-template-columns: rem(20px) rem(60px) rem(60px) rem(60px) auto rem(160px) rem(160px) rem(160px) rem(120px) rem(100px) max-content;
    }
  }
  &__tr {
    cursor: pointer;

    &:has(.row-expanded) {
      &::before {
        max-height: 0;
      }
    }
  }
}

.arrow-icon {
  @include size(11px);
  fill: #abbdcc;
  transition: all 300ms;
  transform: rotate(-90deg);
  pointer-events: none;
  margin: 3px 6px 0px 0px;
  &--in-button {
    fill: #FFFFFF;
  }
  &--expanded {
    transform: rotate(0deg);
  }
}

.bell-icon {
  @include size(19px 22px);
  position: relative;
  &--warning {
    color: var(--warning);
  }
  &--danger {
    color: var(--danger);
  }

  &__text {
    @include font(700 12px "Roboto");
    color : var(--white);
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
  }
}

.article-chart {
  grid-column: 4/10;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  gap: rem(16px);
  margin-top: rem(40px);
}

.info-button {
  margin-right: rem(15px);
}

.expand-button-mobile-view {
  @include bp(12) {
    display: none;
  }
}

.row-background {
  position: absolute;
  top: 0;
  width: 95%;
  height: 100%;
  @include bp(0 12) {
    height: 50px;
  }
}

.row-up {
  animation: blick-up 3s ease-out;
}
@keyframes blick-up {
  from {
    background-color: rgba(0, 255, 0, 0.2);
  }
  to {
    background-color: transparent;
  }
}

.row-down {
  animation: blick-down 3s ease-out;
}
@keyframes blick-down {
  from {
    background-color: rgba(255, 0, 0, 0.2);
  }
  to {
    background-color: transparent;
  }
}
</style>
