<template>
  <div class="dashboard-body">
    <PreloaderFull v-show="!pageCreated" />
    <Select
      v-model="selectedSite"
      :options="topArticleSites"
      :option-value="null"
      id="select_top_page_site"
      :label="$t('dashboard.select_site')"
      class="select-site"
      no-empty-value
      disable-form-group
    />
    <div class="top-charts">
      <div class="top-charts__people-tachometer-chart">
        <div class="top-charts__people-tachometer-chart__title">
          {{ $t('dashboard.people') }}
        </div>
        <h3 class="top-charts__people-tachometer-chart__heading">
          {{ $t('dashboard.peoplemeter_title') }}
        </h3>
        <div>
          <TachometerChart :chart-data="tachometerData" :height="180" />
        </div>
        <div class="tachometer-labels">
          <div>
            {{ tachometerData.labels[0] }}
          </div>
          <div>
            {{ $t('dashboard.peoplemeter_max') }}:
            <span>
              {{ tachometerData.labels[1] }}
            </span>
            <Tooltip :title="$t('dashboard.tooltip.peoplemeter_max')" />
          </div>
        </div>
      </div>
      <div class="top-charts__realtime-statistics-chart">
        <div class="top-charts__people-tachometer-chart__title">
          {{ $t('dashboard.performance') }}
        </div>
        <RealtimeStatisticsChart
          :chart-api-data="statisticsChartApiData"
        />
      </div>
    </div>
    <div v-if="showHistoricalReports" class="historical-pv">
      <div>
        <table>
          <tr>
            <td rowspan="2"> <GoogleDataStudioIcon /> </td>
            <td>{{ $t('dashboard.google_data_studio') }}</td>
          </tr>
          <tr>
            <td class="historical-pv__title">{{ $t('dashboard.historical_pv') }}</td>
          </tr>
        </table>
      </div>
      <button
        class="historical-pv__open-report-button"
        @click="openReport"
      >
        {{ $t('dashboard.open_report') }}
      </button>
    </div>
    <Autoloader
      ref="autoloader"
      v-if="topArticles.length > 0"
      :enabled="!autoRefreshDisabled"
      :callback="getTopArticles"
      :height="3"
      :interval="autoRefresh"
    />
    <div class="table-wrapper">
      <div class="table-filter-wrapper">
        <RealtimeStatisticsFilter
          @on-filter="onFilter"
          class="table-filter-wrapper__filter"
        />
        <div class="table-filter-wrapper__refresh-checkbox">
          <OutlinedCheckbox
            v-model="enableAutoRefreshOnExpanded"
            id="enable_refresh_on_expanded_checkbox"
            :label="$t('dashboard.alwaysRefresh')"
          />
        </div>
      </div>
      <div class="table-header">
        <h2>
            {{ $t('dashboard.top_pages') }} - {{ selectedSiteTitle }}
        </h2>
        <div class="table-header__info">
          <span v-if="autoRefreshDisabled">
            <i class="fas fa-info-circle"></i> {{ $t('dashboard.autoRefreshPaused') }}
          </span>
        </div>
      </div>
      <div>
        <RealtimeStatisticsTable
          :top-articles="topArticles"
          :selected-site="selectedSite"
          @some-expanded="someTableRowExpanded = $event"
        />
      </div>
    </div>
    <p class="info">{{ $t('dashboard.tooltip.page_video_views_hint') }}</p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { dataHubRealtimeApi } from '@/api/datahub'
import Select from '@/components/form/select/Select'
import { SITE_MAP, getNewReportUrl } from '@/model/ValueObject/TopPageSites'
import TachometerChart from '@/components/chart/TachometerChartNew'
import Tooltip from '@/components/tooltip/Tooltip'
import RealtimeStatisticsTable from '@/components/statistics/RealtimeStatisticsTable'
import PreloaderFull from '@/components/preloader/PreloaderFull'
import RealtimeStatisticsChart from '@/components/statistics/RealtimeStatisticsChart'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import RealtimeStatisticsFilter from '@/components/statistics/RealtimeStatisticsFilter'
import RealtimeStatisticsFilterModel from '@/model/RealtimeStatisticsFilterModel'
import RealtimeStatisticsFilterService from '@/services/realtimeStatistics/RealtimeStatisticsFilterService'
import GoogleDataStudioIcon from '@/assets/img/svg/google-data-studio.svg?inline'
import Autoloader from '@/components/Autoloader'

export default {
  name: 'DashboardView',
  data () {
    return {
      autoRefresh: 15, // in seconds // db in Data-hub is updated every 15 seconds, so don't set this lower than 15
      enableAutoRefreshOnExpanded: false,
      someTableRowExpanded: false,
      pageCreated: false,
      selectedSite: null,
      filter: this._.cloneDeep(RealtimeStatisticsFilterModel),
      trackOrderChanges: true,
      topArticleSites: null,
      topArticles: [],
      statisticsChartApiData: {},
      topArticlesLoopInterval: null,
      tachometerData: {
        labels: [],
        datasets: []
      }
    }
  },
  components: {
    RealtimeStatisticsFilter,
    OutlinedCheckbox,
    RealtimeStatisticsChart,
    PreloaderFull,
    RealtimeStatisticsTable,
    Select,
    TachometerChart,
    Tooltip,
    GoogleDataStudioIcon,
    Autoloader
  },
  computed: {
    ...mapState(['callingAPI']),
    ...mapGetters({
      getSiteTitle: 'site/getSiteTitle',
      vlm: 'vlm'
    }),
    selectedSiteTitle () {
      return this.getSiteTitle(this.selectedSite?.id)
    },
    showHistoricalReports () {
      return this.selectedSite?.lvl === 2
    },
    autoRefreshDisabled () {
      return !this.enableAutoRefreshOnExpanded && this.someTableRowExpanded
    }
  },
  methods: {
    onFilter (filter) {
      this.filter = filter
      this.getTopArticles({ forceLoad: true })
    },
    async setTopArticleSites () {
      let tree
      await dataHubRealtimeApi().get('/tree')
        .then(response => {
          tree = response.data
        }).catch(error => {
          console.error(error)
          this.$store.commit('TOGGLE_LOADING', false)
        })
      if (!tree) {
        return
      }
      const options = [{
        id: this.vlm ? 'VLM' : 'NMH',
        title: this.vlm ? 'VLM' : 'NMH',
        lvl: 0
      }]
      const sortedTree = tree.sort((a, b) => this.getSiteTitle(a.parent).localeCompare(this.getSiteTitle(b.parent)))
      sortedTree.forEach(treeBranch => {
        options.push({
          id: treeBranch.parent,
          title: this.getSiteTitle(treeBranch.parent),
          lvl: 1
        })
        const sortedBrands = treeBranch.brand
          .map(brand => ({
            id: brand,
            title: this.getSiteTitle(brand),
            lvl: 2
          }))
          .sort((a, b) => a.title.localeCompare(b.title))
        options.push(...sortedBrands)
      })
      this.topArticleSites = options
      this.selectedSite = options[0]
    },
    async getTopArticles ({ forceLoad } = { forceLoad: false }) {
      if (this.callingAPI && !forceLoad) {
        // usually the load can be skipped as it will run again in a few seconds
        // if this method is called only once (e.g. on-filter) then pass forceLoad: true
        return
      }
      this.$store.commit('TOGGLE_LOADING', true)
      const getApiUrl = (baseUrl, filter) => {
        const { lvl, id } = this.selectedSite
        switch (lvl) {
          case 2:
            filter.brand = id
            filter.parent = undefined
            break
          case 1:
            filter.brand = undefined
            filter.parent = id
            break
          default:
            filter.brand = undefined
            filter.parent = undefined
            break
        }
        const filterQuery = RealtimeStatisticsFilterService.buildFilterQuery(filter)
        return `${baseUrl}${filterQuery}`
      }

      await dataHubRealtimeApi().get(getApiUrl('/dashboard', this.filter)).then(response => {
        this.setTachometerData(response.data.uniqueUsers)
        this.topArticles = this.setOrderChangeClass(response.data.topArticles)
      }).catch(error => {
        console.error(error)
        this.$store.commit('TOGGLE_LOADING', false)
      })
      const historicalFilter = {
        brand: this.filter.brand,
        parent: this.filter.parent
      }
      await dataHubRealtimeApi().get(getApiUrl('/historical', historicalFilter)).then(response => {
        this.statisticsChartApiData = response.data
      }).catch(error => {
        console.error(error)
        this.$store.commit('TOGGLE_LOADING', false)
      })
      this.$store.commit('TOGGLE_LOADING', false)
    },
    setTachometerData (uniqueUsers) {
      const { users, max } = uniqueUsers
      const getUsersColor = () => {
        const colors = {
          low: '#5AB963',
          medium: '#FFA200',
          high: '#F86A6A'
        }
        return users < max * (1 / 3)
          ? colors.low
          : users < max * (2 / 3)
            ? colors.medium
            : colors.high
      }
      this.tachometerData = {
        labels: [
          this.$options.filters.formatNumber(users),
          this.$options.filters.formatNumber(max)
        ],
        datasets: [
          {
            data: [
              users,
              max - users
            ],
            backgroundColor: [
              getUsersColor(),
              '#465674'
            ]
          }
        ]
      }
    },
    setOrderChangeClass (topArticles) {
      let topArticlesPrevious = []
      if (this.trackOrderChanges) {
        topArticlesPrevious = this._.cloneDeep(this.topArticles)
      } else {
        topArticlesPrevious = []
        this.trackOrderChanges = true
      }

      topArticlesPrevious.forEach((topArticlePrev, prevPosition) => {
        topArticles.forEach((topArticleCur, curPosition) => {
          if (topArticlePrev.documentId === topArticleCur.documentId) {
            if (prevPosition < curPosition) {
              topArticleCur.orderChangeDown = true
            }
            if (prevPosition > curPosition) {
              topArticleCur.orderChangeUp = true
            }
            if (prevPosition === curPosition) {
              topArticleCur.orderChangeNone = true
            }
          }
        })
      })
      if (topArticlesPrevious.length > 0) {
        topArticles.forEach((topArticleCur) => {
          let existsInPrev = false
          topArticlesPrevious.forEach((topArticlePrev) => {
            if (topArticlePrev.documentId === topArticleCur.documentId) {
              existsInPrev = true
            }
          })
          if (existsInPrev === false) {
            topArticleCur.orderChangeUp = true
          }
        })
      }
      return topArticles
    },
    async getAll () {
      await this.getTopArticles()
      if (this.$refs.autoloader) {
        this.$refs.autoloader.startCountdown()
      }
    },
    stopAll () {
      if (this.$refs.autoloader) {
        this.$refs.autoloader.refreshCountdown()
      }
    },
    openReport () {
      if (this.selectedSite.lvl === 2) {
        window.open(getNewReportUrl(this.selectedSite.id))
      }
    }
  },
  watch: {
    selectedSite (newVal, oldVal) {
      if (!this.pageCreated) {
        return
      }
      this.stopAll()
      this.trackOrderChanges = false
      this.getAll()
      if (oldVal !== null) {
        this.$gtm.trackView(this.$route.name, newVal?.title)
      }
    }
  },
  mounted () {
    const defaultSite = this.$store.getters['site/all'].find(
      (site) => site.id === this.$store.getters['user/currentUser'].defaultSite
    )
    if (defaultSite && SITE_MAP[defaultSite.name]) {
      this.selectedSite = { id: defaultSite.name }
    }
  },
  async created () {
    this.pageCreated = false
    await this.setTopArticleSites()
    if (this.topArticleSites) {
      await this.getAll()
    }
    this.pageCreated = true
  },
  beforeDestroy () {
    this.stopAll()
  }
}
</script>

<style scoped lang="scss">
.dashboard-body {
  position: relative;
}
.select-site {
  max-width: 200px;
}
.top-charts {
  display: flex;
  gap: 2%;
  margin-top: rem(44px);
  margin-bottom: 2rem;
  @include bp(0 7) {
    flex-wrap: wrap;
  }
  &__people-tachometer-chart {
    width: 200px;
    flex: 0 0 10%;
    background: #FFFFFF;
    padding: 1rem;
    &__title {
      color: #8A96AC;
      @include font(400 14px "Roboto");
      margin-top: rem(-40px);
      margin-bottom: rem(40px);
      margin-left: -1rem;
    }
    &__heading {
      @include font(400 22px "Roboto");
      text-align: center;
      color: #465674;
    }
  }
  &__realtime-statistics-chart {
    width: 200px;
    padding-right: rem(5px);
    background: #FFFFFF;
    padding: 1rem;
    @include bp(0 7) {
      flex: 0 0 100%;
      margin-top: rem(40px);
    }
    @include bp(7) {
      flex: 0 0 81%;
    }
  }
}
.tachometer-labels {
  position: relative;
  width: 100%;
  color: #465674;
  padding-bottom: rem(10px);
}

.tachometer-labels div {
  position: absolute;
  text-align: center;
  width: 100%;
}

.tachometer-labels div:first-child {
  @include font(400 42px "Roboto");
  top: -80px;
}

.tachometer-labels div:last-child {
  top: -20px;
  font-size: 14px;
  span {
    @include font(700 14px "Roboto");
  }
}

.info {
  margin-top: rem(5px);
}

.historical-pv {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 2rem;
  @include font(400 14px "Roboto");
  color: #FFFFFF;
  background: #6599FE;
  box-shadow: 0px 6px 20px rgba(96, 150, 255, 0.6);
  &__title {
    @include font(700 16px "Roboto");
  }
  table {
    td {
      padding-left: 1rem;
    }
    margin-bottom: 1rem;
  }
  &__open-report-button {
    @include font(700 13px "Roboto");
    background: #FFFFFF;
    color: #6599FE;
    cursor: pointer;
    padding: rem(10px) rem(14px);
    width: rem(120px);
    height: rem(40px);
    border: 1px solid #D1DBE4;
    border-radius: rem(6px);
  }
}
.table-wrapper {
  background-color: #FFFFFF;
  padding: rem(10px);
}

.table-header {
  min-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: -2.5rem;
  h2 {
    float: left;
    text-transform: uppercase;
    @include font(700 16px "Roboto");
    margin-left: 0.5rem;
  }
  &__info {
    text-align: right;
    margin-right: 0.5rem;
    color: darken(#1693C1FF, 15%);
    white-space: break-spaces;
  }
}

.table-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  &__filter {
    @include bp(0 11) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      flex: 0 0 66%;
    }
  }
  &__refresh-checkbox {
    @include bp(0 11) {
      flex: 0 0 100%;
    }
    @include bp(11) {
      padding-top: rem(10px);
      flex: 0 0 28%;
    }
   }
}
</style>
