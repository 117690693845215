const buildFilterQuery = function (filter) {
  let filterQuery = '?'
  if (filter.limit) {
    filterQuery += `limit=${filter.limit}&`
  }
  if (filter.documentId) {
    filterQuery += `documentId=${filter.documentId}&`
  }
  if (filter.title) {
    filterQuery += `title=${filter.title}&`
  }
  if (filter.author) {
    filterQuery += `author=${filter.author}&`
  }
  if (filter.brand) {
    filterQuery += `brand=${filter.brand}&`
  }
  if (filter.parent) {
    filterQuery += `parent=${filter.parent}&`
  }
  return filterQuery.slice(0, -1)
}

export default {
  buildFilterQuery
}
