export const AVAILABLE_SITES = [
  {
    id: 'plus1',
    title: 'Plus JEDEN DEŇ'
  },
  {
    id: 'plus7',
    title: 'PLUS 7 DNÍ'
  },
  {
    id: 'emma',
    title: 'EMMA'
  },
  {
    id: 'sport24',
    title: 'Šport 24'
  },
  {
    id: 'sarm',
    title: 'Šarm'
  },
  {
    id: 'polovnictvoarybarstvo',
    title: 'Poľovníctvo a rybárstvo'
  },
  {
    id: 'autobild',
    title: 'Autobild'
  },
  {
    id: 'ncpz',
    title: 'Nový čas pre ženy'
  },
  {
    id: 'eva',
    title: 'Eva'
  },
  {
    id: 'zivot',
    title: 'Život'
  },
  {
    id: 'lepsiebyvanie',
    title: 'Lepšie Bývanie'
  },
  {
    id: 'magazin',
    title: 'Magazín'
  },
  {
    id: 'wanda',
    title: 'Wanda'
  },
  {
    id: 'zena',
    title: 'Žena'
  },
  {
    id: 'trend',
    title: 'Trend'
  },
  {
    id: 'zdravie',
    title: 'Zdravie'
  },
  {
    id: 'dobrejedlo',
    title: 'Dobré jedlo'
  },
  {
    id: 'zahradkar',
    title: 'Záhradkár'
  }
]

export const SITE_MAP = {
  plus1: 'Plus JEDEN DEŇ',
  plus7: 'PLUS 7 DNÍ',
  emma: 'EMMA',
  sport24: 'Šport 24',
  sarm: 'Šarm',
  polovnictvoarybarstvo: 'Poľovníctvo a rybárstvo',
  autobild: 'Autobild',
  ncpz: 'Nový čas pre ženy',
  eva: 'Eva',
  zivot: 'Život',
  lepsiebyvanie: 'Lepšie Bývanie',
  magazin: 'Magazín',
  wanda: 'Wanda',
  zena: 'Žena',
  trend: 'Trend',
  zdravie: 'Zdravie',
  dobrejedlo: 'Dobré jedlo',
  zahradkar: 'Záhradkár'
}

export const getNewReportUrl = (site) =>
  `https://datastudio.google.com/reporting/51e08af9-48b7-4f40-80c7-ab0e94e01aba/page/uwj9?params={"ds26.brand":"${site}"}`

export const REPORT_URL = {
  plus1: 'https://datastudio.google.com/reporting/58eadd47-0021-4482-b38e-d31b9c880c45',
  plus7: 'https://datastudio.google.com/reporting/491c2a65-8954-4bbf-8afe-7da7b9e72a24',
  emma: 'https://datastudio.google.com/reporting/388090ba-076c-4322-b38a-79f4268ee854',
  sport24: 'https://datastudio.google.com/reporting/496a1176-33bf-47b5-89ef-c663a50b8cae',
  sarm: 'https://datastudio.google.com/reporting/17b3b6fa-63e6-4a45-ae51-756989ccd71e',
  polovnictvoarybarstvo: 'https://datastudio.google.com/reporting/4259d618-0d4a-4f9f-a04f-9abf12b6de67',
  autobild: 'https://datastudio.google.com/reporting/ca5959dc-96e9-4b27-ad82-a447a1cbf094',
  ncpz: 'https://datastudio.google.com/reporting/7526d1ea-947c-44ca-b612-60670ebd1591',
  eva: 'https://datastudio.google.com/reporting/ca4876d2-b689-4d7c-a711-181d6622d6e7',
  zivot: 'https://datastudio.google.com/reporting/4047db51-ed84-426e-ad41-60f9da49fcea',
  lepsiebyvanie: 'https://datastudio.google.com/reporting/8b26662d-e3cd-409a-b7b4-12c15776c39c',
  magazin: 'https://datastudio.google.com/reporting/98fe1606-b144-47e4-9c38-ab4cc96b99c3',
  wanda: 'https://datastudio.google.com/reporting/38de829f-7f0e-4053-9b6c-3c2a4e2002f2',
  zena: 'https://datastudio.google.com/reporting/4bc9c981-65e5-421b-bf2a-4fb6bd992297',
  trend: 'https://datastudio.google.com/reporting/e8edea80-1f8d-450a-9bd8-ec9064929acf',
  zdravie: 'https://datastudio.google.com/reporting/8ffcb90b-81bd-453e-91fa-a5422e72c30b',
  zahradkar: 'https://datastudio.google.com/reporting/632f3d69-4f41-496c-acc1-49d00dc5adf9',
  dobrejedlo: 'https://datastudio.google.com/reporting/1c32255e-dca7-459b-9fc7-fb12bb9bf932',
  ujco: 'https://datastudio.google.com/reporting/7d1b6ada-2cea-4992-9eba-119408d5ae69'
}
