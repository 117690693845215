<template>
  <AbstractFilter
    :on-filter="filterStatistics"
    :on-filter-reset="resetFilter"
    :show-create-button="false"
    filter-panel-class="filter-panel-realtime-statistics"
  >
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('dashboard.filter.title')"
    />
    <Input
      v-model="filter.documentId"
      id="filter_documentId"
      :label="$t('dashboard.filter.documentId')"
    />
    <Input
      v-model="filter.author"
      id="filter_author"
      :label="$t('dashboard.filter.author')"
    />
  </AbstractFilter>
</template>

<script>

import Input from '@/components/form/inputs/Input'
import AbstractFilter from '@/components/filter/AbstractFilter'
import RealtimeStatisticsFilterModel from '@/model/RealtimeStatisticsFilterModel'

export default {
  name: 'RealtimeStatisticsFilter',
  components: {
    AbstractFilter,
    Input
  },
  data () {
    return {
      filter: this._.cloneDeep(RealtimeStatisticsFilterModel)
    }
  },
  methods: {
    filterStatistics () {
      this.$emit('on-filter', this.filter)
    },
    resetFilter () {
      this.filter = this._.cloneDeep(RealtimeStatisticsFilterModel)
      this.$emit('on-filter', this.filter)
    }
  }
}
</script>

<style lang="scss">
.filter-panel-realtime-statistics{
  @include bp(0 7) {
    grid-template-columns: 1fr;
  }
  @include bp(7) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
