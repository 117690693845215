import { Doughnut, mixins } from 'vue-chartjs'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  data () {
    return {
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 0,
            bottom: -10
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false, // tooltips are also disabled in method drawDashedTachometer() below
          mode: 'single',
          callbacks: {
            label: (tooltipItems, data) => {
              return data.labels[tooltipItems.index]
            }
          }
        },
        rotation: Math.PI,
        circumference: Math.PI,
        cutoutPercentage: 91,
        animation: {
          duration: 0 // see method drawDashedTachometer()
        }
      }
    }
  },
  watch: {
    chartData () {
      // TODO CMS-145 - fix me - dashed tachometer is broken on small display
      // this.drawDashedTachometer()
    }
  },
  methods: {
    /**
     *
     * This draws 'public/img/tachometer.png' that contains only the
     *   dashed part of the chart that is always the same
     *   as there is no easy way to draw it with the chart component.
     * Also, the 'animation' in options above is set to 0,
     *   otherwise the tachometer.png would not be drawn.
     */
    drawDashedTachometer () {
      const canvas = document.getElementById('doughnut-chart')
      /**
       * this prevents from reloading of the original canvas on hover
       * it also cancels the 'tooltips' in options above
       */
      canvas.style['pointer-events'] = 'none'
      const image = new Image()
      image.src = '/img/tachometer.png'
      image.onload = function () {
        const context = canvas.getContext('2d')
        context.drawImage(image, 16, 0 + 68, canvas.width / 1.5 - 4, canvas.height / 1.5 - 86)
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
